* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  transition: 0.4s ease-in-out;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;

}

container {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.container2 {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  
}
.container3 {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
  background-color: #ffffff;
  
}


header {
  position: absolute;
  z-index: 99;
  width: 100%;
  padding: 0.5px;
  background-color: #ffffff;

  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .logo {
      font-weight: 700;
      letter-spacing: 2px;
      color: rgb(0, 0, 0);
      margin-left: 10px;
      font-size: 15px;
      margin-bottom: 2px;
     
    }
     .contact {
      font-weight: 600;
      letter-spacing: 2px;
      color: rgb(0, 0, 0);
      font-size: 15px;
      margin-right: 5px;
      margin-bottom: 2px;
      
     }
    nav {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        li {
          list-style: none;
          margin: 0 60px;
          &.btn {
            a {
              color: rgb(0, 0, 0);
              font-weight: 700;
              background: white;
              padding: 4px 7px;
              border-radius: 0px;
      
            }
          }
          a {
            text-transform: capitalize;
            text-decoration: none;
            color: rgb(0, 0, 0);
          }
        }
      }
    }
  }
}

.title {
  font-size: 50px;
  text-align: left;
  width: 100%;
  margin: 0 auto;
  color: rgb(0, 0, 0);
  margin-top: 200px;
  margin-left: 15px ;
  span {
    display: block;
  }
  @media (max-width: 1440px) {
    font-size: 25px;
    margin-top: 200px;
    margin-left: 15px ;
   
  }
}

.title2 {
  font-size: 5px;
  text-align: left;
  width: 100%;
  margin: 20px auto;

  color: rgb(0, 0, 0);
  span {
    display: block;
  }
  @media (max-width: 1440px) {
    font-size: 3rem;
  }
}

.text {
  font-size: 33px;
  text-align: left;
  width: 100%;
  margin: 0 auto;
  color: rgb(0, 0, 0);
  margin-left: 20px ;
    
  span {
    display: block;
  }
  @media (max-width: 1440px) {
    font-size: 12px;
    margin-left: 15px ;
    
  }
}

.text2 {
  font-size: 14px;
  text-align: left;
  width: 100%;
  margin: 0 auto;
  color: rgb(0, 0, 0);
 
  
  span {
    display: block;
  }
  @media (max-width: 1440px) {
    font-size: 5px;
    margin-top: 40px;
    margin-left: 15px;
    
    
    
  }
}

.text3 {
  font-size: 24px;
  text-align: left;
  width: 100%;
  margin: 0 auto;
  color: rgb(0, 0, 0);
 
  
  span {
    display: block;
  }
  @media (max-width: 1440px) {
    font-size: 11px;
    margin-top: 40px;
    margin-left: 15px;
    
    
    
  }
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #7f7f7f;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-bar-container {
  width: 100px;
  height: 32px;
  background: #ffffff;
}

.loading-bar {
  height: 32px;
  background: #000000;
}
